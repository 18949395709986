<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-quotation-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead v-if="userRole !== 'Associate'" :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ dateToday }}</span>
            </div>

            <!-- Quotation number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <v-select
                v-model="quotation.branch_id"
                :items="branchOptions"
                item-value="id"
                item-text="name"
                single-line
                outlined
                dense
                hide-details
                clearable
                placeholder="Select Branch"
                class="quotation-add-branch"
              ></v-select>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <!-- Customer Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Quoted To:
                  </p>
                  <v-text-field
                    v-model="quotation.company_name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Company Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Quotation Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-none d-md-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <span>Description</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Amount</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <span>Summable Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>

          <!-- Insurance Product Type -->
          <v-card
            outlined
            class="d-flex pa-3"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.insurance_product_id"
                  :items="insuranceProductOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance Product Type"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>

          <!-- Car Registration Number -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.car_registration_number"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Car Registration Number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.insurance_id"
                  :items="insuranceOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Insurance"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>

          <!-- Sum Insured -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Sum Insured
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.sum_insured"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Surplus Hibah -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Surplus Hibah
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.surplus_hibah"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- No Claim Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.no_claim_discount"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="No Claim Dicsount %"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Period -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.period"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Period (days)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Insurance Premium -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Insurance Premium
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.insurance_premium"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- After Discount -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  After Discount
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.after_discount"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Road Tax Price -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Road Tax Price
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.road_tax"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- Pickup Location -->
          <v-card
            v-if="isMotorInsurance"
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="quotation.pickup_location_id"
                  :items="pickupLocationOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="Select Road Tax Pickup Location"
                ></v-select>

                <template>
                  <v-text-field
                    v-if="isOtherPickupLocation"
                    v-model="quotation.remarks.pickup_location"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Enter Road Tax Pickup Location"
                    class="mt-3"
                  ></v-text-field>
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Additional Fees -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex align-center"
              >
                <p class="mb-0">
                  Additional Fees
                </p>
              </v-col>
              <v-col
                cols="12"
                offset-sm="3"
                sm="3"
              >
                <v-text-field
                  v-model="quotation.additional_fees"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="quotation.remarks.general"
                  dense
                  outlined
                  hide-details="auto"
                  placeholder="General Remarks"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <v-divider></v-divider>

        <!-- Quotation Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ userData.nickname }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="createQuotation()"
          >
            {{ t('Confirm Quotation') }}
          </v-btn>

          <div class="text-center mt-4">
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import Alert from '@/components/Alert'
import quotationStoreModule from './quotationStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const { router } = useRouter()
    const QUOTATION_APP_STORE_MODULE_NAME = 'app-quotation'

    if (!store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(QUOTATION_APP_STORE_MODULE_NAME, quotationStoreModule)
    }

    // Properties
    const quotation = ref({
      company_name: null,
      insurance_product_id: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      no_claim_discount: null,
      period: null,
      sum_insured: null,
      surplus_hibah: null,
      insurance_premium: null,
      after_discount: null,
      road_tax: null,
      additional_fees: null,
      remarks: {
        general: null,
        pickup_location: null,
      },
    })
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const dateToday = new Date().toLocaleDateString('en-SG')
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const loading = ref(false)

    // Methods
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-quotation/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-quotation/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-quotation/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup location. Please refresh!')
        })
    }
    const createQuotation = () => {
      loading.value = true
      errors.value = []
      store
        .dispatch('app-quotation/createQuotation', quotation.value)
        .then(response => {
          const { data } = response.data
          router.push(`/apps/quotation/preview/${data.id}`)
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving quotation. Please refresh!')
        })
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === quotation.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== quotation.value.pickup_location_id) quotation.value.remarks.pickup_location = null

      return otherPickupLocationId.value === quotation.value.pickup_location_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(quotation.value.after_discount) || 0.00
      const roadTax = parseFloat(quotation.value.road_tax) || 0.00
      const additionalFees = parseFloat(quotation.value.additional_fees) || 0.00

      return parseFloat(afterDiscount + roadTax + additionalFees).toFixed(2)
    })

    // Mounted
    onMounted(() => {
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      quotation,
      errors,
      createQuotation,
      userData,
      userRole,
      dateToday,
      branchOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      calculatedTotal,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-quotation-editable {
  .quotation-add-branch {
    width: 15rem;
  }
}
</style>
